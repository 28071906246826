<template>
	<div :class="themeClass" class="width-fill page-box">
		<!-- <div class="margin-tb-10">
			<breadcrumb></breadcrumb>
		</div> -->
		<div class="top-bg-box">
			<el-carousel class="gcm-box-top">
				<el-carousel-item class="bannerImg" v-for="(item,index) in bannerList" :key="index">
					<img class="bannerImg" :src="item.fPath" />
				</el-carousel-item>
			</el-carousel>
			<div class="flex-row-space-between maink navigation-box">
				<div class="navigation-box-left position-relative">
					<div class="menuOverFlow">
						<div v-for="(i,index) of leftMenu" :key="index" :class="current==i.goodsClass.fGoodsClassID?'font-color-40C6A9':'font-color-333'"
						 class="leftMenuItem flex-row-center-center pointer" @mouseover="moveMainInFun(i.goodsClass)" @mouseleave="moveMainLeaveFun(i.goodsClass)">
							<!-- <span class="iconfont icon-wenju margin-r-6"></span> -->
							<span class="font-weight700 font-size14">{{i.goodsClass.fGoodsClassFullName}}</span>
						</div>
					</div>
					<transition name="el-zoom-in-left">
						<div @mouseover="moveDetailInFun()" @mouseleave="moveDetailLeaveFun()" v-show="moveMainShow == true || moveDetailsShow == true"
						 class="left-item-list-box position-absolute padding-10">
							<!-- <div v-for="(item,index) of leftMenuDetail" :key="index"> -->
							<div class="flex-row">
								<div class="left-item-title font-size12 font-color-fff font-weight700">{{leftMenuCurrent}}</div>
							</div>
							<div class="flex-row-wrap font-size12 font-color-666">
								<div v-for="(item1,index1) of leftMenuDetail" :key="index1" class="left-item-detailed pointer-color-theme"
								 @click="goGoldGoods(item1.goodsClass.fGoodsClassID)">{{item1.goodsClass.fGoodsClassFullName}}</div>
							</div>
							<!-- </div> -->
						</div>
					</transition>
				</div>

				<div class="navigation-box-right flex-column-align-center" style="padding-top: 23px;">
					<!-- <img class="gcm-box-top-avator" src="../../assets/imgs/GoldCoinMall/avator.png" /> -->
					<div v-if="getUserInfo != null" class="text-center">
						<el-avatar :size="60" :src="url"></el-avatar>
						<div class="username font-size14 padding-10">
							<span>{{getUserInfo.fUserName}}</span>
						</div>
					</div>
					<div v-if="getUserInfo == null" @click="loginUrl" class="text-center">
						<el-avatar :size="60" :src="url"></el-avatar>
						<div class="username font-size14" style="text-align: center;">
							<span v-if="getUserInfo == null" style="cursor: pointer;">请登录</span>
						</div>
					</div>

					<!-- <div class="company font-size12 font-color-999">ONE技术有限公司</div> -->
					<div class="flex-row font-color-0E8D7A gold-box">
						<div class="gold" v-if="getUserInfo!=null">{{fEndBalance}}</div>
						<div class="gold" v-else>0</div>
						<div class="gold-text font-size14">金币</div>
					</div>

					<!-- <div class="flex-row-align-center">
						<div class="gcm-box-top-avator-box bg-fff flex-row-center-center">
							<img class="gcm-box-top-avator" src="../../assets/imgs/GoldCoinMall/avator.png" />
						</div>
						<div class="margin-l-10">
							<div class="font-size14 font-color-0E8D7A">
								<span class="gcm-box-top-content-num font-size36 font-weight700 margin-r-5">{{fEndBalance}}</span>金币
							</div>
						</div>
					</div> -->
					<div class="font-size12 font-color-666 integral-box">
						积分兑换金币，可在金币商城购买商品
					</div>
					<div class="line"></div>
					<!-- <div class="padding-tb-10 border-bottom-F2F2F2">
						<div class="font-size12">
							我的成长等级
						</div>
						<div class="flex-row-align-center membership-level">
							<div class="flex-column-center-center padding-tb-10 position-relative">
								<div class="member-img-box position-relative">
									<img class="member-img" src="../../assets/imgs/GoldCoinMall/huiyuan.png" />
								</div>
								<div class="font-size12 margin-t-6 font-color-FFBB2D">当前普通</div>
								<div class="strip position-absolute">
									<div class="strip-bg position-absolute"></div>
								</div>
							</div>
							<div class="flex-column-center-center padding-tb-10 position-relative membership-level-two">
								<div class="member-img-box position-relative">
									<img class="member-img" src="../../assets/imgs/GoldCoinMall/huiyuan(hui).png" />
								</div>
								<div class="font-size12 margin-t-6 font-color-ccc">黄金会员</div>
							</div>
						</div>
						<div class="font-size12 font-color-666">
							还差5206分成长值升级为黄金会员
						</div>
					</div> -->
					<div class="flex-row-wrap nav-box">
						<div v-for="(item,index) of navigationButton" :key="index" v-if="index != 3" class="navigation-box-right-button pointer flex-column-center-center"
						 :class="index<3?((index+1)%3==0?'border-botton-dashed':'border-right-dashed border-botton-dashed'):((index+1)%3==0?'':'border-right-dashed')"
						 @click="selectNavigationButton(item)">
							<div class="icon-box-bg flex-row-center-center">
								<div class="iconfont button-iconfont-color font-size16" :class="item.icon"></div>
							</div>
							<div class="font-size12 icon-box-title font-color-666">{{item.title}}</div>
						</div>
						<div v-for="(item,index) of navigationButton" :key="index" v-if="index == 3" class="navigation-box-right-button pointer flex-column-center-center"
						 :class="index<3?((index+1)%3==0?'border-botton-dashed':'border-right-dashed border-botton-dashed'):((index+1)%3==0?'':'border-right-dashed')"
						 @click="selectNavigationButton(item)">

							<div class="icon-box-bg flex-row-center-center" style="position: relative;">
								<div v-if="cartNums != 0" class="" style="width: 18px;height: 18px;border-radius: 40px;background: red;text-align: center;line-height: 18px;position: absolute;right: -8px;top: -8px;color: #fff;font-size: 12px;">{{cartNums}}</div>
								<div class="iconfont button-iconfont-color font-size16" :class="item.icon"></div>
							</div>
							<div class="font-size12 icon-box-title font-color-666">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="gold-mall-content-box maink">
			<!-- <div class="flex-colum-space-between bg-fff margin-t-10 padding-10">
				<div class="gcm-box-bottom-card-one-nav flex-row-space-between-center">
					<div class="font-size18 font-weight700 flex-row-align-center">
						<div class="font-color-FF0000">XIANSHI /</div>
						<div class="margin-l-10 font-color-000">限时福利</div>
					</div>
					<div class="font-size14 font-color-000 pointer" @click="jumpMore">
						更多<span class="iconfont icon-youkuohao"></span>
					</div>
				</div>
				<div class="gcm-box-bottom-card-one-list flex-row-space-between-center">
					<div class="gcm-box-bottom-card-one-item flex-column-center-center" v-for="(item,index) in cardOneList" :key="index"
					 @click="jumpCommodity">
						<div class="gcm-box-bottom-card-one-item-img-box flex-row-center-center">
							<img class="gcm-box-bottom-card-one-item-img" :src="item.img" mode=""/>
						</div>
						<div class="gcm-box-bottom-card-one-title font-size14 twoline-show-hide margin-t-20">
							{{item.title}}
						</div>
						<div class="gcm-box-bottom-card-one-price  font-size12 font-color-FF0000">
							<span class="font-weight700 font-size16 padding-l-10">{{item.price}}</span>金币
						</div>
					</div>
				</div>
			</div> -->
			<!-- <div class="gcm-box-bottom-card-two flex-row-space-between">
				<div class="gcm-box-bottom-card-two-left flex-colum-start-between">
					<div class="font-size28 font-weight700">
						我们一起租兑吧
					</div>
					<div class="font-size18">
						这个夏天 你快乐兑了吗
					</div>
				</div>
				<img class="gcm-box-bottom-card-two-right" src="../../assets/imgs/GoldCoinMall/gcm_bg.png" />
			</div> -->
			<!-- 抽奖活动 -->
			<div v-if="drawAwardPath" class="box-advert width-1100 flex-row-center-center margin-b-20 margin-t-20 pointer">
				<img :src="drawAwardPath" style="height: 90px; width: 100%; border-radius: 50px; " class="width-1100" @click="toDrawAward" />
			</div>
			
			<div v-if="goldCoinBuyList.length>0" class="carouselBox">
				<el-carousel :autoplay="true" :interval="interval" height="96px">
					<el-carousel-item class="width-fill" v-for="(item1, index) in goldCoinBuyList" :key="index">
						<img :src="item1.fPath" class="wh-100" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="gcm-box-bottom-card-three padding-10 margin-t-10 bg-fff">
				<div class="gcm-box-bottom-card-one-nav flex-row-space-between-center">
					<div class="font-size18 font-weight700 flex-row-align-center">
						<!-- <div class="font-color-FF0000">DUIHUAN /</div> -->
						<div class="margin-l-10 font-color-000">新品推荐</div>
					</div>
					<div class="font-size14 font-color-000 pointer" @click="more">
						更多<span class="iconfont icon-youkuohao"></span>
					</div>
				</div>
				<div v-if="hotGoodsList.length==0" class="flex-row-center-center">
					<svg-icon icon-class="NoContent" />
				</div>
				<div class="gcm-box-bottom-card-one-list flex-row-wrap">
					<div class="gcm-box-bottom-card-one-item flex-column-center-center margin-t-10" @click="getdetail(item.fGoodsID)"
					 v-for="(item,index) in hotGoodsList" :key="index" :class="(index+1)%5==0?'':'margin-r-10'">
						<div class="gcm-box-bottom-card-one-item-img-box flex-row-center-center">
							<img class="gcm-box-bottom-card-one-item-img" :src="item.fPicturePath" mode="" />
						</div>
						<div class="gcm-box-bottom-card-one-title font-size14 twoline-show-hide margin-t-20">
							{{item.fGoodsFullName}}
						</div>
						<div class="flex-row width-fill" v-if="item.fActivityID != -1">
							<div class="gcm-box-bottom-card-one-price  font-size12 font-color-FF0000 margin-r-10">
								<span class="font-weight700 font-size16 padding-lr-5">{{item.fActivityPrice}}</span>金币
							</div>
							<div class="gcm-box-bottom-card-one-price  font-size12 flex-row-align-center" style="text-decoration: line-through;">
								<span class="font-weight700 font-size12 padding-lr-5">{{item.fGoodsPrice}}</span>金币
							</div>
						</div>
						<div class="flex-row width-fill" v-else>
							<div class="gcm-box-bottom-card-one-price  font-size12 font-color-FF0000">
								<span class="font-weight700 font-size16 padding-lr-5">{{item.fGoodsPrice}}</span>金币
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import parm from '@/components/loyout/publicList'
	import breadcrumb from '@/components/breadcrumb.vue';
	export default {
		components: {
			breadcrumb
		},
		data() {
			return {
				drawAwardPath: '', //抽奖活动
				interval: 3000,
				url: require("@/assets/personal.png"),
				fEndBalance: '',
				leftItemShow: false,
				isMoveMain: false,
				moveMainShow: false,
				// 移入明细分类
				isMoveDetail: false,
				moveDetailsShow: false,
				cartNums: 0,
				listData: [],
				// 左侧菜单
				leftMenu: [],
				// 当前选中的左侧菜单id
				current: '',
				// 当前的选中的左侧菜单名称
				leftMenuCurrent: '',
				// 当前选中的左侧菜单对应的详细菜单
				leftMenuDetail: [],
				// 金币商城首页banner轮播图
				bannerList: [],
				// banner测试数据
				// bannerList:[{
				// 	fPath: 'c622cdf1da7cb8057ad939f9e2e1b830.jpg'
				// }, {
				// 	fPath: 'c622cdf1da7cb8057ad939f9e2e1b830.jpg'
				// }, {
				// 	fPath: 'c622cdf1da7cb8057ad939f9e2e1b830.jpg'
				// }, {
				// 	fPath: 'c622cdf1da7cb8057ad939f9e2e1b830.jpg'
				// }, ],
				// 金币商城首页热门兑换商品
				hotGoodsList: [],
				navigationButton: [{
						id: 1,
						icon: 'icon-zhangdan1',
						title: '金币账单',
					},
					{
						id: 2,
						icon: 'icon-jifen2',
						title: '兑换金币'
					},
					{
						id: 3,
						icon: 'icon-chanpindingdan',
						title: '金币订单'
					},
					{
						id: 4,
						icon: 'icon-gouwucheman',
						title: '购物车'
					},
					{
						id: 6,
						icon: 'icon-jiangquan',
						title: '抽奖券'
					},
					// {
					// 	id: 5,
					// 	icon: 'icon-wujiaoxing',
					// 	title: '商品收藏'
					// }
				], //跳转按钮
				navItems: parm.navItems,
				cardOneList: parm.cardOneList,
				cardThreeList: parm.cardThreeList,
				goldCoinBuyList: [], //轮播广告图
			};
		},
		computed: {
			...mapGetters(['getUserInfo']),
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
    activated() {
      this.getList();  //获取金币数
      this.getMenuList()//获取左侧数据
      this.getcartnum()  //获取购物车里面的数量
    },
    mounted() {
			this.getBannerList()
			this.getGoldCoinHotGoods()
			this.getGoldCoint()
			this.getDrawAward(); //获取抽奖活动
			if (this.getUserInfo.fFileName) {
				this.url = this.getUserInfo.fFileName;
			}
		},
		methods: {
			//跳转抽奖页面
			toDrawAward() {
				if (this.$store.getters.getUserInfo == null) {
					return this.$store.dispatch('setShowLogin', true);
				}
				const status = this.$store.getters.getUserInfo.fVerifiedStatus;
				if (!status) {
					return this.$store.dispatch('setShowAttestation', true);
				}
				console.log('跳转抽奖页面');
				this.$router.push({
					name: 'luckMainPlatform',
					query: {
						// shopId: this.fShopUnitID,
						shopId: '5'
					}
				});
			},
			//获取抽奖活动
			getDrawAward() {
				this.ApiRequestPostNOMess('/api/mall/ebactivity/luck-draw-award-pool/get-self-support-currettime').then(
					res => {
						console.log('获取抽奖活动drawAwardPath', res);
						this.drawAwardPath = res.obj[0].fWebFullPath
						// this.fShopUnitID
					},
					rej => {}
				);
			},
			// 首页获取购物车数量
			getcartnum() {
				this.ApiRequestPost('api/mall/ebsale/shopping-cart/get-gold-coin-shopping-cart-count').then(res => {
					console.log(res, '金币商城首页购物车数量')
					this.cartNums = res.obj.number
				}, error => {

				});
			},
			// 鼠标 移入 主分类区域
			moveMainInFun(goodsClass) {

				this.moveMainShow = true;
				this.isMoveMain = true;
				this.moveDetailsShow = true;
				// this.leftMenu  = JSON.parse(JSON.stringify(this.classifyList[index].goodsClassProperties))
				this.selectLeftItem(goodsClass);
			},
			// 鼠标 移出 明细分类区域
			moveDetailLeaveFun() {

				let _this = this;
				this.isMoveDetail = false;
				setTimeout(function() {
					// isMoveMain 是否进入分类
					if (_this.isMoveMain == false && _this.moveDetailsShow == true) {
						_this.moveDetailsShow = false;
						_this.moveMainShow = false;

					}
				}, 300)
			},
			// 鼠标 移入 明细分类区域
			moveDetailInFun() {
				this.isMoveDetail = true;
				this.moveDetailsShow = true;
			},
			moveMainLeaveFun(index) {

				this.isMoveMain = false;
				let _this = this;
				setTimeout(function() {
					// isMoveDetail 是否进入明细分类
					if (_this.isMoveDetail == false && _this.moveDetailsShow == true) {
						_this.moveDetailsShow = false;
						_this.moveMainShow = false;

					}
				}, 300)
			},
			loginUrl() {
				this.$router.push({
					name: 'login'
				});
			},
			register() {

				this.$router.push({
					name: 'register'
				});
			},
			// 获取金币商城左侧分类
			getMenuList() {
				this.ApiRequestPost('api/mall/goods/goods-class/get-golden-navigations', {}).then(result => {
					console.log(result);
					this.leftMenu = result.obj.goodsClasses.slice(0, 13)
					console.log(this.leftMenu);
				}, rej => {})
			},
			// 获取金币商城banner轮播图
			getBannerList() {
				this.ApiRequestPost('api/mall/ebbase/ebadvertisement/get-gold-homepage-ad-list', {
					fAppTypeID: this.fAppTypeID
				}).then(result => {
					console.log(result);
					this.bannerList = result.obj
					console.log('this.bannerList', this.bannerList);
				}, rej => {})
			},
			// 点击二级分类获取商品
			goGoldGoods(id) {
				console.log(id);
				this.$router.push({
					path: "/goldMall/goldGoods",
					query: {
						id: id
					}
				});
			},
			// 获取金币商城热门兑换商品
			getGoldCoinHotGoods() {
				// api/mall/ebsale/goods-point-price/get-gold-coin-new-goods新品
				// api/mall/ebsale/goods-point-price/get-gold-coin-hot-goods热销
				this.ApiRequestPost('api/mall/ebsale/goods-point-price/get-gold-coin-new-goods', {}).then(result => {
					console.log('热门商品获取成功')
					console.log(result);
					this.hotGoodsList = result.obj.items
				}, rej => {})
			},
			// 点击更多
			more() {
				this.$router.push({
					path: "/goldMall/goldGoods",
				});
			},
			getList() {

				this.ApiRequestPostNOMess('api/mall/ebbalance/customer-gold-coin/get-my-gold-coin', {

				}).then(
					result => {

						this.fEndBalance = result.obj.fEndBalance;

					},
					rej => {}
				);
			},
			selectNavigationButton(e) {
				//金币账单
				if (e.id == 1) {
					// console.log(e.title);
					this.$router.push('/businessme/GoldBill')
				}
				//兑换金币
				if (e.id == 2) {
					console.log(e.title);
					this.$router.push('/goldMall/ExchangeG')
				}
				//金币订单
				if (e.id == 3) {
					console.log(e.title);
					this.$router.push('/goldMall/GoldOrder')
				}
				//购物车
				if (e.id == 4) {
					console.log(e.title);
					this.$router.push('/goldMall/GoldShoppingCart')
				}
				//购物车
				if (e.id == 6) {
					console.log(e.title);
					this.$router.push('/PersonalContent/goldCoinLotteryTicket')
				}
				// //商品收藏
				// if (e.id == 5) {
				// 	console.log(e.title);
				// 	this.$router.push('/goldMall/GoldMallCollection')
				// }
			},
			selectLeftItem(val) {


				// 重复点击置空,关闭弹出层
				// if (this.current == val.fGoodsClassID) {
				// 	console.log('重复点击');
				// 	this.current = ''
				// 	this.leftItemShow = false;
				// 	return
				// }

				this.isMoveMain = true;
				this.current = val.fGoodsClassID
				this.leftMenuCurrent = val.fGoodsClass
				// 给左侧菜单对应的详细分类赋值
				this.leftMenu.forEach(item => {
					if (item.goodsClass.fGoodsClassID == val.fGoodsClassID) {
						this.leftMenuDetail = item.goodsClasses
					}
				})

			},
			jumpPage() {

			},
			// 跳转商品详情
			jumpCommodity() {

			},
			// 跳转商品更多列表
			jumpMore() {

			},
			// 兑换金币
			jumpExchange() {
				this.$router.push('/exchangeG')
			},
			getdetail(id) {
				this.$router.push({
					path: '/goldMall/goodsDetails',
					query: {
						id: id
					}
				})
			},
			//获取轮播广告图
			getGoldCoint() {
				this.ApiRequestPostNOMess('/api/mall/ebbase/ebadvertisement/get-gold-coin-buy-list', {
					fAppTypeId: this.fAppTypeID
				}).then(
					result => {
						// console.log('获取轮播广告图', result);
						this.goldCoinBuyList = result.obj;
					},
					rej => {}
				);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.leftMenuItem {
		padding-bottom: 14px;

		&:last-child {
			padding-bottom: 13px;
		}
	}

	.page-box {
		background-color: #F8F9FE;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.top-bg-box {
		background-color: #daf8f0;
	}

	.navigation-box {
		margin: 0 auto;
		// box-shadow: 0px 0px 10px rgba(204, 204, 204, 0.35);
	}

	.navigation-box-left {
		box-sizing: border-box;
		width: 171px;
		height: 404px;
		background-color: #FFFFFF;
	}

	.navigation-box-right {
		z-index: 1;
		width: 220px;
		height: 381px;
		background-color: #FFFFFF;
	}

	.icon-box-bg {
		// background-color: #d9f7ef;
		width: 30px;
    border-radius: 9px;
		height: 30px;
		margin-bottom: 6px;
		background: inherit;
		background-color: rgba(217, 247, 239, 1);
	}

	.icon-box-title {
		height: 15px;
		line-height: 15px;
	}

	.gold-mall-content-box {
		margin: auto;
		margin-bottom: 20px;
	}


	.font-size36 {
		font-size: 36px;
	}

	.membership-level-two {
		margin-left: 105px;
	}

	.gcm-box-top {
		position: absolute;
		width: 100%;
		height: 404px;
		z-index: 0;
		// background-image: url(../../assets/imgs/GoldCoinMall/goldCoinMall_bg_1.png);
		// background-size: 100% 100%;
	}

	.bannerImg {
		width: 100%;
		height: 100%;
	}

	/deep/ .el-carousel__container {
		width: 100%;
		height: 100%;
	}

	.el-carousel::v-deep .el-carousel__arrow {
		// display: none;
	}

	.gcm-box-top-avator-box {
		width: 54px;
		height: 54px;
	}

	.gcm-box-top-avator {
		width: 54px;
		height: 54px;
		border: 2px solid #ffffff;
		border-radius: 4px;
		margin: 18px auto 13px;
	}

	.username {
		height: 16px;
		line-height: 16px;
	}

	.company {
		height: 15px;
		line-height: 15px;
		margin-top: 3px;
	}

	.font-color-0E8D7A {
		color: #0E8D7A;
	}

	.gold-box {
		margin: 13px auto 12px;
	}

	.gold {
		height: 42px;
		line-height: 42px;
		font-size: 36px;
	}

	.gold-text {
		height: 16px;
		line-height: 16px;
		margin: 18px 0 8px 5px;
	}

	.gcm-box-top-content-num {
		line-height: 70px;
	}

	.strip {
		width: 154px;
		background-color: #CCCCCC;
		top: 19px;
		left: 22px;
		height: 5px;
	}

	.strip-bg {
		background-color: #FFBB2D;
		width: 50%;
		top: 0px;
		left: 0px;
		height: 5px;
	}


	.integral-box {
		margin-bottom: 28px;
	}


	.gcm-box-bottom-card-one-nav {
		width: 100%;
		height: 48px;
	}

	.button-iconfont-color {
		color: #0e8d7a;
	}


	.gcm-box-bottom-card-one-list {
		margin-top: 10px;
		width: 100%;
	}

	.gcm-box-bottom-card-one-item {
		border: 1px solid #eee;
		width: 206px;
		height: 289px;
		cursor: pointer;
	}

	.gcm-box-bottom-card-one-item-img-box {
		width: 186px;
		height: 186px;
	}

	.gcm-box-bottom-card-one-item-img {
		width: 160px;
		height: 160px;
	}

	.gcm-box-bottom-card-one-title {
		width: 194px;
		height: 40px;
	}

	.gcm-box-bottom-card-one-price {
		// width: 100%;
	}

	.gcm-box-bottom-card-two {
		margin-top: 20px;
		border-radius: 10px;
		background-color: #B8DDDC;
	}

	.gcm-box-bottom-card-two-left {
		padding: 12px 0 12px 107px;
		color: #0f695f;
	}

	.gcm-box-bottom-card-two-right {
		width: 331px;
		height: 89px;
	}

	.line {
		width: 200px;
		height: 1px;
		background-color: #f2f2f2;
	}

	.nav-box {
		width: 200px;
		margin-top: 5px;
	}

	.navigation-box-right-button {
		width: calc(200px/3);
		height: 85px;
		box-sizing: border-box;
	}

	.border-botton-dashed {
		border-bottom: 1px dashed #F2F2F2;
	}

	.border-right-dashed {
		border-right: 1px dashed #F2F2F2;
	}

	.left-item-list-box {
		top: 0px;
		left: 171px;
		background-color: #f8f9fe;
		// box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
		min-width: 689px;
		max-width: 689px;
		height: 384px;
	}

	.left-item-title {
		padding: 5px 10px;
		background-color: #333333;
		// box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
		margin-bottom: 10px;
	}

	.left-item-detailed {
		margin: 0px 20px 10px 0;
	}

	.left-item-detailed:hover {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.member-img-box {
		width: 21px;
		height: 21px;
		z-index: 1;
	}

	.member-img {
		width: 21px;
		height: 21px;
	}

	.wh-100 {
		width: 100%;
		height: 100%;
	}

	.carouselBox {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.carouselBox::v-deep {
		width: 100%;
		height: 89px;

		.el-carousel .el-carousel__indicators {
			display: none;
		}
	}

	.menuOverFlow{
		box-sizing: border-box;
		padding-top: 13px;
		width: 171px;
		height: 404px;
		background-color: #FFFFFF;
		overflow-y: scroll;
		overflow-x: hidden;
	}
</style>
